<template>
  <div>
    <b-row class="no-gutters">
      <b-col class="col-12 col-md-8 col-lg-6">
        <h4 class="font-weight-normal text-muted title__2">
          <p>{{ $t("page.audience.pageDescription") }}</p>
        </h4>
        <b-form>
          <b-card no-body class="p-3 mt-4" v-if="showViewerSelector">
            <h6 class="mb-0 text-muted">
              {{ $t("page.audience.videoViewerQuestion") }}
            </h6>
            <hr class="mt-2 mb-4 mx-n3" />
            <b-form-select
              v-model="selectedViewer"
              id="viewer"
              class="input-field"
            >
              <b-form-select-option
                v-for="(viewer, index) in viewerOptions"
                :key="index"
                :value="viewer.value"
              >
                {{ viewer.text }}
              </b-form-select-option>
            </b-form-select>
          </b-card>

          <!-- PANEL PROVIDER SELECTOR -->
          <b-card no-body class="p-3 mt-4" v-if="showProviderSelector">
            <h6 class="mb-0 text-muted">
              {{ $t("page.audience.providerQuestion") }}
            </h6>
            <hr class="mt-2 mb-4 mx-n3" />
            <b-form-select
              v-model="selectedPanelProvider"
              id="panelProvider"
              class="input-field"
              :options="panelProviderOptions"
            >
              
            </b-form-select>
          </b-card>

          <b-card no-body class="p-3 mt-4">
            <h6 class="mb-0 text-muted">
              {{ $t("page.audience.informationText") }}
              <b-link
                target="_blank"
                :href="getExternalLink(aboutLink)"
                class="text-red"
                >{{ $t("page.audience.here") }}</b-link
              >
            </h6>
            <hr class="mt-2 mb-4 mx-n3" />
            <div>
              <div class="mb-2 font-weight-bold">
                {{ $t("page.audience.selectCountryQuestion") }}
              </div>
              <b-form-select
                v-model="selectedCountry"
                id="country"
                class="input-field"
              >
                <template v-slot:first>
                  <b-form-select-option :value="''" disabled>
                    {{ $t("selectAnOption") }}
                  </b-form-select-option>
                </template>
                <b-form-select-option
                  v-for="(countryInfo, index) in countryOptions"
                  :key="index"
                  :value="countryInfo"
                >
                  {{ countryInfo.countryName }}
                </b-form-select-option>
              </b-form-select>
            </div>
            <div class="mt-3">
              <div class="mb-2 font-weight-b old">
                {{ $t("page.audience.videoBrandQuestion") }}
              </div>
              <b-form-select
                v-model="brandCategory"
                :options="brandCategoryItems"
                class="input-field"
                value-field="id"
                text-field="name"
              >
                <template v-slot:first>
                  <b-form-select-option :value="''" disabled>
                    {{ $t("selectAnOption") }}
                  </b-form-select-option>
                </template>
              </b-form-select>
            </div>
            <b-row class="no-gutters mt-4 px-1">
              <b-col class="col-12 col-lg-6 h-100">
                <p class="mb-0 font-weight-bold">{{ $t("population") }}</p>
                <!-- SELECT AUDIENCE -->
                <b-form-radio-group v-model="targetGroup">
                  <b-form-radio
                    v-for="(listItem, jIndex) in audienceList"
                    :key="jIndex"
                    :value="listItem"
                    class="mt-3"
                    >{{ $t(`page.audience.${listItem}.title`) }}</b-form-radio
                  >
                </b-form-radio-group>
              </b-col>
              <b-col class="col-12 col-lg-6 h-100 left-border pl-3">
                <p class="font-weight-bold">{{ $t("segmentation") }}</p>
                <div v-if="isTargetGroupDefined">
                  <p class="font-weight-bold text-muted mb-0">
                    {{ $t("age") }}
                  </p>
                  <p class="subtitle__1">{{ getDefaultAgeDescription }}</p>
                  <p class="font-weight-bold text-muted mt-1 mb-0">
                    {{ $t("gender") }}
                  </p>
                  <p class="subtitle__1">{{ getDefaultGenderDescription }}</p>
                </div>
              </b-col>
            </b-row>
            <div v-if="isCustomerUser">
              <div class="font-weight-bold mt-5 mb-1">
                {{ $t("page.audience.askForRefineMsg") }}
              </div>
              <b-form-checkbox
                v-model="activeRefineAudience"
                name="check-button"
                switch
                size="lg"
              >
              </b-form-checkbox>
              <b-collapse
                id="collapse-4"
                v-model="activeRefineAudience"
                class="mt-2"
              >
                <div class="mt-5 mb-2 font-weight-bold">{{ $t("gender") }}</div>
                <checkbox-card-group
                  :options="genderOptions"
                  :selected="selectedGenderList"
                  :translationPath="''"
                  @change="setSelectedGenderList"
                />
                <div class="mt-5 mb-2 font-weight-bold">{{ $t("age") }}</div>
                <checkbox-card-group
                  :options="ageRangeOptions"
                  :selected="selectedAgeRangeList"
                  :translationPath="'page.audience.ageRange'"
                  @change="setSelectedAgeRangeList"
                />
                <div class="mt-5 mb-2 font-weight-bold">{{ $t("region") }}</div>
                <b-form-checkbox-group
                  v-if="selectedCountry"
                  v-model="selectedRegionList"
                >
                  <b-row class="no-gutters">
                    <b-card
                      v-for="(region, index) in selectedCountryRegions"
                      :key="index"
                      class="p-2 mr-1 mb-1"
                      :border-variant="
                        isRegionSelected(region) ? 'primary' : ''
                      "
                      no-body
                    >
                      <b-form-checkbox :value="region">
                        {{ region.name }}
                      </b-form-checkbox>
                    </b-card>
                  </b-row>
                </b-form-checkbox-group>
                <div v-else class="font-italic font-weight-light">
                  {{ $t("page.audience.regionNotAvailable") }}
                </div>
              </b-collapse>
            </div>
          </b-card>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Utils from "./../Utils";
import Config from "../plugins/Config";
import CheckboxCardGroup from "./../components/CheckboxCardGroup";

export default {
  components: {
    CheckboxCardGroup,
  },
  data() {
    return {
      pageTitle: "page.audience.title",
      audienceModalStatus: false,
      panelProvider: "",
      audienceList: [
        Utils.AUDIENCE_TARGET_GROUP.NATIONAL,
        Utils.AUDIENCE_TARGET_GROUP.PRIMARY,
      ],
      aboutLink: {
        hrefEn: "https://emotiva.it/en/empower-audience/",
        hrefIt: "https://emotiva.it/empower-audience-2/",
      },
    };
  },
  created() {
    // this.$store.commit("audience/RESET");
  },
  
  methods: {
    getExternalLink(linkItem) {
      return Utils.getExternalLinkByLang({
        linkEn: linkItem.hrefEn,
        linkIt: linkItem.hrefIt,
      });
    },
    isRegionSelected(curRegion) {
      return this.selectedRegionList.find(
        (region) => region.region_id === curRegion.region_id
      );
    },
    setSelectedGenderList(list) {
      this.selectedGenderList = list;
    },
    setSelectedAgeRangeList(list) {
      this.selectedAgeRangeList = list;
    },
    setSelectedRegionList(list) {
      this.selectedRegionList = list;
    },
    openAudiencePreferenceDialog() {
      this.audienceModalStatus = true;
    },
    setAudienceModalStatus(status) {
      this.audienceModalStatus = status;
    },
    resetSelectedCountry() {
      this.$store.commit("audience/SET_SELECTED_COUNTRY", '');
      this.$store.commit("audience/SET_SELECTED_REGION_LIST", []);
  }
    
  },
  computed: {
    isCustomerUser() {
      return (
        this.$store.getters["auth/getUserType"] === Utils.USER_TYPE.CUSTOMER
      );
    },
    showProviderSelector() {
      return false;
    },
    showViewerSelector() {
      const emailsAllowed = ["study@emotiva.it","empower-demo@emotiva.it"];
      const currentUserEmail =
        this.$store.getters["auth/getUserAccount"].registration?.email;
      return (
        emailsAllowed.includes(currentUserEmail) &&
        (Config.value("showViewerSelector") || "false") === "true"
      );
    },
    audiencePreference() {
      return this.$t(`page.audience.${this.targetGroup}.title`);
    },
    audiencePreferenceSubtitle() {
      return this.$t(`page.audience.${this.targetGroup}.description`);
    },
    countryOptions() {
      const countries = this.$store.getters["audience/getCountryOptions"];
      
      switch (this.selectedPanelProvider) {
        case 'pure-spectrum':
          return countries.filter(country => country.regionsPureSpectrum && country.regionsPureSpectrum.length > 0); 
        case 'dynata':
        default:
          return countries.filter(country => country.regions && country.regions.length > 0);
      }
    },
    selectedCountry: {
      get() {
        return this.$store.getters["audience/getSelectedCountry"];
      },
      set(country) {
        this.$store.commit("audience/SET_SELECTED_COUNTRY", country);
      },
    },
    selectedCountryRegions(){
      switch (this.selectedPanelProvider) {
        case 'pure-spectrum': return this.selectedCountry.regionsPureSpectrum
        case 'dynata':
        default:
          return this.selectedCountry.regions
      }
      
    },
    viewerOptions() {
      return this.$store.getters["audience/getViewerOptions"];
    },
    panelProviderOptions() {
      return this.$store.getters["audience/getPanelProviderOptions"];
    },
    selectedPanelProvider: {
      get() {
        return this.$store.getters["audience/getselectedPanelProvider"];
      },
      set(panelProvider) {
        this.$store.commit(
          "audience/SET_SELECTED_PANEL_PROVIDER",
          panelProvider
        );
        this.panelProvider = panelProvider;
      },
    },

    selectedViewer: {
      get() {
        return this.$store.getters["audience/getSelectedViewer"];
      },
      set(viewer) {
        this.$store.commit("audience/SET_SELECTED_VIEWER", viewer);
      },
    },
    brandCategoryItems() {
      return this.$store.getters["audience/getBrandCategoryOptions"];
    },
    brandCategory: {
      get() {
        return this.$store.getters["audience/getBrandCategory"];
      },
      set(brandCategory) {
        this.$store.commit("audience/SET_BRAND_CATEGORY", brandCategory);
      },
    },
    genderOptions() {
      return this.$store.getters["audience/getGenderOptions"];
    },
    targetGroup: {
      get() {
        return this.$store.getters["audience/getTargetGroup"];
      },
      set(targetGroup) {
        this.$store.commit("audience/SET_TARGET_GROUP", targetGroup);
      },
    },
    selectedGenderList: {
      get() {
        return this.$store.getters["audience/getSelectedGenderList"];
      },
      set(genderList) {
        this.$store.commit("audience/SET_SELECTED_GENDER_LIST", genderList);
      },
    },
    activeRefineAudience: {
      get() {
        return this.$store.getters["audience/getActiveRefineAudience"];
      },
      set(refineAudience) {
        if (!refineAudience) {
          this.selectedGenderList = this.genderOptions;
          this.selectedAgeRangeList = this.ageRangeOptions;
          this.selectedRegionList = this.selectedCountryRegions;
        }
        this.$store.commit(
          "audience/SET_ACTIVE_REFINE_AUDIENCE",
          refineAudience
        );
      },
    },
    ageRangeOptions() {
      return this.$store.getters["audience/getAgeRangeOptions"];
    },
    selectedAgeRangeList: {
      get() {
        return this.$store.getters["audience/getSelectedAgeRangeList"];
      },
      set(ageRangeList) {
        this.$store.commit(
          "audience/SET_SELECTED_AGE_RANGE_LIST",
          ageRangeList
        );
      },
    },
    selectedRegionList: {
      get() {
        return this.$store.getters["audience/getSelectedRegionList"];
      },
      set(regionList) {
        this.$store.commit("audience/SET_SELECTED_REGION_LIST", regionList);
      },
    },
    respondentOptions() {
      return this.$store.getters["audience/getRespondentOptions"];
    },
    selectedRespondent: {
      get() {
        return this.$store.getters["audience/getSelectedRespondent"];
      },
      set(selectedRespondent) {
        if (selectedRespondent !== "OTHER")
          this.$store.commit(
            "audience/SET_NUMBER_OF_RESPONDENTS",
            selectedRespondent
          );
        else this.$store.commit("audience/SET_NUMBER_OF_RESPONDENTS", null);
        this.$store.commit(
          "audience/SET_SELECTED_RESPONDENT",
          selectedRespondent
        );
      },
    },
    isTargetGroupDefined() {
      switch (this.targetGroup) {
        case Utils.AUDIENCE_TARGET_GROUP.NATIONAL:
        case Utils.AUDIENCE_TARGET_GROUP.PRIMARY:
          return true;
        default:
          return false;
      }
    },
    getDefaultAgeDescription() {
      return this.$t(`page.audience.${this.targetGroup}.defaultAgePercentMsg`);
    },
    getDefaultGenderDescription() {
      return this.$t(
        `page.audience.${this.targetGroup}.defaultGenderPercentMsg`
      );
    },
  },
  watch: {
    panelProvider: function (panelProvider) {
      console.log("getting new categories fro provider: ", panelProvider);
      // Change project's panel provider
      // const clientId = this.$store.getters["auth/getClientId"];
      // const projectId = this.$store.getters["project/getActiveProjectId"];
      // const payload = {};
      this.resetSelectedCountry(); // Reset country
      this.$store.commit("audience/SET_SELECTED_PANEL_PROVIDER",panelProvider)
      
      
    },
  },
};
</script>

<style scoped>
.left-border {
  border-left: 1px solid black;
}
.input-field {
  max-width: 600px;
}
</style>
